/* 
 * @Author: 曹俊杰 
 * @Date: 2022-06-08 14:22:37
 * @Module: 发布曝光
 */
 <template>
  <div class="exposureForm">
    <div class="header">
      <!-- <el-button type="text" icon="el-icon-close">关闭</el-button> -->
      <el-link icon="el-icon-close" :underline="false" @click="$router.go(-1)">关闭</el-link>
      <h1>我要曝光</h1>
      <el-button type="primary" size="small" @click="submitForm">提交</el-button>
    </div>
    <div class="exposureContent">
      <div class="title">
        我的信息
      </div>
      <div style="padding:0 24px;">
        <el-form label-position="left" label-width="70px">
          <el-row :gutter="140">
            <el-col :span="12">
              <el-form-item label="曝光方">
                <el-input :value="user.company" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="曝光账号">
                <el-input :value="user.name" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="solid"></div>
      <div class="title">
        违约方信息
      </div>
      <el-form label-position="left" ref="form" hide-required-asterisk label-width="70px" :model="form" :rules="rules">
        <div style="padding:0 24px;">
          <el-row :gutter="140">
            <el-col :span="12">
              <el-form-item label="违约主播" prop="name">
                <el-input v-model="form.name" placeholder="请输入违约主播姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号" prop="idNumber">
                <el-input v-model="form.idNumber" placeholder="请输入违约主播身份证号码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="solid"></div>
        <div class="title">
          违约情况
        </div>
        <div style="padding:0 24px;">
          <el-form-item label-width="0" prop="content">
            <el-input :maxlength="100" show-word-limit type="textarea" :rows="5" placeholder="请描述主播违约的情况…" v-model="form.content">
            </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
 <script>


import { mapState } from "vuex";
export default {
  components: {},
  data () {
    const idCardValidator = (_, value, callback) => {
      if (this.$utils.test.idCard(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的身份证号'));
      }
    }
    return {
      form: {
        name: '',
        idNumber: '',
        content: ''
      },
      rules: {
        name: [{ required: true, message: '违约主播姓名不能为空', trigger: 'blur' }],
        content: [{ required: true, max: 100, message: '长度控制在100字以内', trigger: 'blur' }],
        idNumber: [
          { required: true, message: '违约主播身份证号不能为空', trigger: 'blur' },
          { validator: idCardValidator, trigger: 'blur' }
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    })
  },
  mounted () { },
  methods: {
    async postExposure () {
      const { status, info } = await this.$api.postExposure(this.form)
      if (status === 200) {
        this.$message({ type: "success", message: info })
        this.$router.go(-1)
      } else {
        this.$message({ type: 'error', message: info })
      }
    },
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postExposure()
        } else {
          return false;
        }
      });
    }
  },
};
 </script>
 <style lang='scss' scoped>
.exposureForm {
  .header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 64px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    h1 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #262626;
      line-height: 22px;
    }
  }
  .exposureContent {
    padding-top: 64px;
    background: #fff;
    min-height: 100vh;
    .title {
      line-height: 50px;
      background: #ffffff;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #262626;
      padding: 0 24px;
    }
    .solid {
      height: 1px;
      border-bottom: 1px solid #e3e3e3;
      margin: 0 17px;
    }
  }
}
</style>